<template>
  <div>
    <el-row justify="space-between" align="middle">
      <el-col :span="6"><h3 class="mb-0">Notification List</h3></el-col>
<!--      <el-col :span="6">-->
<!--        <el-input v-model="search" placeholder="Please input"/>-->
<!--      </el-col>-->
    </el-row>
    <br>
    <el-table :data="filteredList" border v-loading="loading" style="width: 100%">
      <el-table-column prop="from" label="From" width="300">
        <template #default="scope">
          <UserCard :name="scope.row.from_user_name" phone_number="" :image="scope.row.from_user_image"></UserCard>
        </template>
      </el-table-column>
      <el-table-column prop="message_to" label="Notification"/>
    </el-table>

  </div>
</template>

<script>
import axios from 'axios';
import UserCard from '../../components/core/UserCard';

export default {
  name: 'NotificationList',
  components: {
    UserCard,
  },
  data() {
    return {
      tableData: [
        {
          from: '',
        },
        {
          message_to: '',
        },
      ],
      search: '',
      loading: false,
    };
  },
  created() {
    this.getPrescription();
  },
  computed: {
    filteredList() {
      return this.tableData.filter(
        (data) => !this.search
              || data.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  methods: {
    getPrescription() {
      this.loading = true;
      axios.get('/api/v2/notification/list')
        .then((response) => {
          this.tableData = response.data.data;
          this.loading = false;
        });
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      }
      return '';
    },
  },
  title: 'Notification List',
};
</script>

<style scoped>

</style>
